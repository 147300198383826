import {ActionTree, GetterTree, MutationTree} from 'vuex'
import sdk from '@/lib/kepler/sdk'
import {FlowInputStep} from '@/lib/kepler/interfaces'
import Vue from 'vue'
import collect from 'collect.js'
import {RootState} from '@/store'
import Utils from '@/utils'
import fakeData from '@/lib/fakeData'

export interface FlowInput {
  steps: FlowInputStep[],
  auto_open: number,
  dialog: number,
  loading_message?: string,
}

export class FlowInputsState {
  [flowName: string]: FlowInput
}

const mutations: MutationTree<FlowInputsState> = {
  PURGE_FLOWS(stateF) {
    stateF = new FlowInputsState()
  },
  SAVE_FLOWS(stateF, payload: FlowInputsState) {
    Utils.flushObject(stateF)
    Utils.setProps(payload, stateF)
  },
  EDIT_INPUT(stateF, payload: { path: string[], value: any }) {
    if (payload.path.length) {
      Utils.setProp(stateF, payload.path, payload.value)
    }
  },
  TOGGLE_DEV_FLOW(stateF) {
    if (stateF.hasOwnProperty('devFlow')) {
      Vue.delete(stateF, 'devFlow')
    } else {
      Vue.set(stateF, 'devFlow', fakeData.devFlow)
    }
  },
}

const actions: ActionTree<FlowInputsState, RootState> = {
  init({commit, dispatch}) {
    commit('PURGE_FLOWS')
    dispatch('getFlows')
  },
  purge({commit}) {
    commit('PURGE_FLOWS')
  },
  login({commit, dispatch}) {
    commit('PURGE_FLOWS')
    dispatch('getFlows')
  },
  getFlows({commit}) {
    return sdk.flows.get().then((r) => {
      commit('SAVE_FLOWS', r.data)
      return r.data
    })
  },
  updateFlows({commit}, payload: FlowInputsState) {
    commit('SAVE_FLOWS', payload)
  },
  editInputFlow({state, commit}, payload: { path: string[], value: any }) {
    commit('EDIT_INPUT', payload)
  },
  toggleDevFlow({commit}) {
    commit('TOGGLE_DEV_FLOW')
  },
}

const getters: GetterTree<FlowInputsState, RootState> = {
  autoOpenQueue: (s) => collect(s)
    .filter((f) => !!f.auto_open)
    .filter((f) => !!f.steps.length || !f.steps.every((step) => step.skip))
    .keys()
    .sort((a, b) => (s[b].auto_open - s[a].auto_open))
    .all() || [],
}

export default {
  state: new FlowInputsState(),
  mutations,
  actions,
  getters,
}
