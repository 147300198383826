


































































































































































































































































































































import {Component, Prop} from 'vue-property-decorator'
import Validations from '@/lib/Validations'
import {Action} from 'vuex-class'
import Utils from '@/utils'

import {extend} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import {Estimate, FlowInputField as Field, FlowInputStep} from '@/lib/kepler/interfaces'
import sdk from '@/lib/kepler/sdk'
import {mixins} from 'vue-class-component'
import {FlowMixin} from '@/components/flow/FlowMixin'
import {EventBus} from '@/main'

for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation,
  })
}

@Component({
  components: {
    SameAs: Utils.loadComponent('fields/SameAs'),
    Location: Utils.loadComponent('flow/flowFields/Location'),
    Layout: Utils.loadComponent('proxy/Layout'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    ParkingLots: Utils.loadComponent('flow/flowFields/ParkingLots'),
    RadioCards: Utils.loadComponent('flow/flowFields/RadioCards'),
    Invite: Utils.loadComponent('flow/flowFields/Invite'),
    DateRangeField: Utils.loadComponent('fields/DateRangeField'),
    QRCode: Utils.loadComponent('flow/flowFields/QRCode'),
    MultipleChoice: Utils.loadComponent('flow/flowFields/MultipleChoice'),
    MultipleInvoices: Utils.loadComponent('flow/flowFields/MultipleInvoices'),
    DateTimeRangeField: Utils.loadComponent('fields/DateTimeRangeField'),
    RadioButtons: Utils.loadComponent('flow/flowFields/RadioButtons'),
    FlowLogin: Utils.loadComponent('flow/flowFields/Login'),
    FlowPlans: Utils.loadComponent('flow/flowFields/Plans'),
    CheckboxFieldWithValidation: Utils.loadComponent('fields/CheckboxFieldWithValidation'),
    PaymentMethodsWithValidation: Utils.loadComponent('fields/PaymentMethodsWithValidation'),
    RegistrationDocumentsComponent: Utils.loadComponent('registration/RegistrationDocumentsComponent'),
    RegistrationPhoneVerification: Utils.loadComponent('registration/RegistrationPhoneVerification'),
    RegistrationAddressComponent: Utils.loadComponent('registration/RegistrationAddressComponent'),
    CountriesFieldWithValidation: Utils.loadComponent('fields/CountriesFieldWithValidation'),
    SelectFieldWithValidation: Utils.loadComponent('fields/SelectFieldWithValidation'),
    PhoneFieldWithValidation: Utils.loadComponent('fields/PhoneFieldWithValidation'),
    DateFieldWithValidation: Utils.loadComponent('fields/DateFieldWithValidation'),
    TextFieldWithValidation: Utils.loadComponent('fields/TextFieldWithValidation'),
    Summary: Utils.loadComponent('flow/flowFields/Summary'),
    Picture: Utils.loadComponent('registration/RegistrationImageComponent'),
    PasswordFieldWithValidation: Utils.loadComponent('fields/PasswordFieldWithValidation'),
    Selfie: Utils.loadComponent('registration/RegistrationSelfieComponent'),
    RegistrationFieldTos: Utils.loadComponent('registration/RegistrationFieldTos'),
    ChangeLangDialog: Utils.loadView('ChangeLangDialog'),
    Verification: Utils.loadComponent('fields/Verification'),
  },
  name: 'FlowField',
})

export default class FlowField extends mixins<FlowMixin>(FlowMixin) {
  @Action('editField') public editField!: (payload: { path: string[], value: any }) => void
  @Action('editInputFlow') public editInput!: (payload: { path: string[], value: any }) => void

  @Prop() public step!: FlowInputStep
  @Prop() public field!: Field
  @Prop() public disabled?: boolean

  public rules: any = Validations.rules
  public currentRules: any[] = []
  public required: boolean = false
  public valid: boolean = false
  public loading: boolean = false

  public get fieldID() {
    const context = this.step?.context
    const fieldArr = this.field.id?.split('.')
    if (fieldArr) {
      if (context) {
        fieldArr.unshift(context)
      }
      const prop = Utils.getProp(this.flowOutputState as Record<string, any>, fieldArr)
      if (typeof prop === 'boolean') {
        return prop
      }
      return prop || this.field.value || null
    }
    return null
  }

  public set fieldID(value) {
    const context = this.step?.context
    const path = this.field.id.split('.')
    if (context) {
      path.unshift(context)
    }
    this.editField({path, value})
  }

  public parseRules() {
    const arr = []
    const val = this.field.validation

    if (val) {
      if (typeof val === 'string') {
        const v = val.split('|')
        if (v.includes('required')) {
          this.required = true
          arr.push(this.rules.required)
        }
        if (v.includes('email')) {
          arr.push(this.rules.email)
        }
      } else if (typeof val === 'object') {
        if (val.hasOwnProperty('required')) {
          this.required = true
          arr.push(this.rules.required)
        }
        if (val.hasOwnProperty('email')) {
          arr.push(this.rules.email)
        }
      }
    }

    return arr
  }

  public lockAndLoad(b: boolean) {
    EventBus.$emit('flow-lock', b)
    this.$nextTick(() => {
      EventBus.$emit('flow-loading', b)
    })
    this.loading = b
  }

  public saveDynamicInput(remap: string, value: any) {
    const flowName = this.step?.context
    if (flowName) {
      const input = this.flowInputs[flowName]
      if (input?.steps.length) {
        const steps = input.steps
        const stepIndex = steps.findIndex((step) => step.id === this.step.id)
        if (stepIndex >= 0) {
          const fields = steps[stepIndex].fields
          if (fields?.length) {
            const fieldIndex = fields.findIndex((field) => field.id === this.field.id)
            const path = `${flowName}.steps.${stepIndex}.fields.${fieldIndex}.dynamic_parsed`
            this.editInput({path: path.split('.'), value: [remap, value]})
          }
        }
      }
    }
  }

  public parseDynamic() {
    const dyn = this.field.dynamic
    const dynParsed = this.field.dynamic_parsed
    if (dyn && !this.disabled) {
      this.lockAndLoad(true)
      const remap = (value: any) => {
        this.$set(this.field, dyn.remap, value)
        this.saveDynamicInput(dyn.remap, value)
      }
      const endpoint = dyn.input
      const output = dyn.input_format
      const context = this.step?.context
      let payload = null
      if (output) {
        payload = this.getPayload(output, context)
        sdk.flows.send(endpoint, payload)
          .then((r) => {
            remap(this.parseMappings(dyn.mappings, r.data))
            this.lockAndLoad(false)
          })
          .catch(() => {
            this.lockAndLoad(false)
          })
      }
    } else if (dynParsed) {
      this.$set(this.field, dynParsed[0], dynParsed[1])
    }
  }

  protected parseMappings(mappings: string | { [key: string]: string }, input: any) {
    if (typeof mappings === 'string') {
      if (mappings === 'rent_segments') {
        const sg = input.segments as any[]
        const arr: any[] = []
        sg.forEach((p) => {
          let price: string | null
          const minSegment = p.segment_rates[0] ?? null
          if ('display_text' in minSegment) {
            price = minSegment.display_text
          } else {
            const minPrice = minSegment.price.time_cost + minSegment.price.distance_cost
            price = minPrice ? this.$t('common.from_price', {n: this.$currency(minPrice)}) : null
          }
          arr.push({
            value: p.id,
            title: p.name,
            text: p.description,
            attributes: p.attributes,
            subtitle: p.hint,
            image: p.image,
            price,
          })
        })
        return arr
      } else if (mappings === 'rent_parkings') {
        const pl = input.parking_lots as any[]
        const arr: any[] = []
        pl.forEach((p) => {
          arr.push({
            value: p.id,
            title: p.name,
            extendedtext: `<div style="max-width: 100%" class="layout column px-3"><b class="mx-auto my-2">${p.description}</b><img class="fill-width" src="${p.picture}" alt=""></div>`,
          })
        })
        return arr
      } else if (mappings === 'rent_rates') {
        const sg = input.segments as any[]
        const arr: any[] = []
        sg.forEach((p) => {
          p.segment_rates.forEach((r: {
            id: string,
            name: string,
            price: Estimate,
            description?: string,
            extended_description?: string,
          }) => {
            arr.push({
              value: r.id,
              title: r.name,
              subtitle: p.name,
              info: r.extended_description,
              price: r.price.time_cost + r.price.distance_cost,
              text: r.description,
            })
          })
        })
        return arr
      }
    }
  }

  protected created() {
    this.parseDynamic()
    this.currentRules = this.parseRules()
    if (this.field.type === 'hidden') {
      const value = this.field.value
      const defaultValue = this.field.validation?.split('|').find((s: string) => s.includes('default'))?.split(':')[1]
      if (value) {
        this.fieldID = value
      } else if (defaultValue) {
        this.fieldID = defaultValue
      }
    }
    if (!this.fieldID && this.field.value) {
      this.$set(this, 'fieldID', this.field.value)
      this.fieldID = this.field.value
    }
  }

}
