import {ActionTree, GetterTree, MutationTree} from 'vuex'
import sdk from '@/lib/kepler/sdk'
import {
  AccountStatus as AccountStatusInterface,
  Autorenew,
  Client,
  Document,
  Driver,
  LoginByPhoneCodeRequest,
  LoginByPhoneRequest,
  LoginRequest,
  LoginResponse,
  ModifyRequest,
  ProfileData,
  ProfileImageRequest,
  ProfilePatchRequest,
  QuickRegistrationRequest,
  Report,
  ResetPasswordRequest,
  Subscription,
  UserMesh,
  Wallet,
} from '@/lib/kepler/interfaces'
import {EventBus} from '@/main'
import {DriverLicense, RegistrationRequest} from '@/lib/kepler/RegistrationRequest'
import {AxiosError, AxiosResponse} from 'axios'
import {Position} from '@/lib/location'
import {Vue} from 'vue-property-decorator'
import {RootState} from '@/store'
import {FirebaseHelper} from '@/lib/FirebaseHelper'

type QuickRegistrationResponse = Client & LoginResponse

export class ProfileState {
  public profileData: ProfileData | null = null
  public driver: Driver | null = null
  public wallets: Wallet[] = []
  public drivers: Driver[] = []
  public subscriptions: Subscription[] = []
  public reports: Report[] = []
  public client: Client | null = null
  public userMesh: UserMesh[] = []
  public documents: Document[] = []
  public language: string = ''
  public userPosition: Position | null = null
  public status: AccountStatusInterface = {
    client: false,
    driver: false,
    document: false,
    payment_method: false,
    signed_tocs: false,
    subscription: false,
  }
  public debugMode: boolean = false
  public translationMode: boolean = false
  public debugTab: number = 0
  public showSplash: boolean = true
}

const mutations: MutationTree<ProfileState> = {
  LOGIN(stateO, payload) {
    stateO.driver = payload
  },
  CLIENT(stateO, payload) {
    stateO.client = payload
  },
  DRIVER(stateO, payload) {
    stateO.driver = payload
  },
  DRIVERS(stateO, payload) {
    stateO.drivers = payload
  },
  USER_MESH(stateO, payload) {
    stateO.userMesh = payload
  },
  SUBSCRIPTION(stateO, payload: Subscription) {
    if (stateO.subscriptions !== undefined) {
      stateO.subscriptions.push(payload)
    } else {
      stateO.subscriptions = [payload]
    }
  },
  AUTORENEW(stateO, payload: Subscription) {
    const id = payload.id
    const updateAutorenew = (s: Subscription) => {
      return s.id === id
    }
    if (stateO.subscriptions !== undefined) {
      const i = stateO.subscriptions.findIndex(updateAutorenew)
      stateO.subscriptions[i].auto_renew = payload.auto_renew
    }
  },
  SUBSCRIPTIONS(stateO, payload: Subscription[]) {
    stateO.subscriptions = payload
  },
  REPORTS(stateO, payload) {
    stateO.reports = payload.reverse()
  },
  PURGE_PROFILE(stateO) {
    stateO.driver = null
    stateO.client = null
    stateO.drivers = []
    stateO.subscriptions = []
    stateO.reports = []
    stateO.documents = []
    stateO.userMesh = []
    stateO.status = {
      client: false,
      driver: false,
      document: false,
      payment_method: false,
      signed_tocs: false,
      subscription: false,
    }
  },
  DOCUMENTS(stateO, payload: Document[]) {
    stateO.documents = payload
  },
  SET_LANG(stateO, payload: string) {
    stateO.language = payload
  },
  SET_USER_POSITION(stateO, payload: Position) {
    stateO.userPosition = payload
  },
  SET_DRIVER_PROFILE_PICTURE(stateO, payload: string) {
    stateO.driver!.profile_picture = payload
    if (stateO.driver) {
      Vue.set(stateO.driver, 'profile_picture', payload)
    }
  },
  SET_ACCOUNT_STATUS(stateO, payload: AccountStatusInterface) {
    if (!this.isMainDriver) {
      payload.subscription_driver = payload.subscription
    }
    stateO.status = payload
  },
  SET_PROFILE_DATA(state0, payload: ProfileData) {
    state0.profileData = payload
  },
  SET_DEBUG_MODE(state0, payload: boolean) {
    state0.debugMode = payload
  },
  SET_TRANSLATION_MODE(state0, payload: boolean) {
    state0.translationMode = payload
  },
  SET_DEBUG_TAB(state0, payload: number) {
    state0.debugTab = payload
  },
  SET_SPLASH_SHOW(state0, payload: boolean) {
    state0.showSplash = Boolean(payload)
  },
}

const actions: ActionTree<ProfileState, RootState> = {
  purge({commit}) {
    commit('PURGE_PROFILE')
  },
  getProfile({commit, dispatch}) {
    return sdk.profile.get().then((r) => {
      commit('SET_PROFILE_DATA', r.data.data)
      commit('DRIVER', r.data.driver)
      dispatch('sleep', 'profile_and_driver')
      return r.data
    })
  },
  patchProfile({commit, dispatch}, payload: ProfilePatchRequest) {
    return sdk.profile.patch(payload).then((r) => {
      commit('SET_PROFILE_DATA', r.data.data)
      commit('DRIVER', r.data.driver)
      dispatch('sleep')
      EventBus.$emit('driver')
      return r.data
    })
  },
  getClient({commit, dispatch}) {
    return sdk.people.client()
      .then((r) => {
        commit('CLIENT', r.data)
        dispatch('sleep', 'client')
        return r.data
      })
  },
  updateClient({commit, dispatch}, payload: ModifyRequest): Promise<Client> {
    return sdk.people.clientEdit(payload)
      .then((r: AxiosResponse<Client>) => {
          commit('CLIENT', r.data)
          dispatch('sleep', 'update_client')
          EventBus.$emit('client')
          return r.data
        },
      )
  },
  getUserMesh({commit, dispatch}) {
    return sdk.people.userMesh()
      .then((r: AxiosResponse<UserMesh[]>) => {
          commit('USER_MESH', r.data)
          dispatch('sleep', 'user_mesh')
          return r.data
        },
      )
  },
  getDrivers({commit, dispatch}) {
    return new Promise<void>((resolve) => {
      sdk.people.drivers()
        .then((r: AxiosResponse<[Driver]>) => {
          commit('DRIVERS', r.data)
          dispatch('sleep', 'drivers')
          resolve()
        })
    })
  },
  getDriver({commit, dispatch}): Promise<Driver> {
    return new Promise((resolve, reject) => {
      sdk.people.driver()
        .then((r) => {
          commit('DRIVER', r.data)
          dispatch('updateProfilePicture', r.data.profile_picture)
          dispatch('sleep', 'driver')
          EventBus.$emit('driver')
          resolve(r.data)
        }).catch(reject)
    })
  },
  updateDriver({commit, dispatch}, payload): Promise<Driver> {
    return sdk.people.driverEdit(payload)
      .then((r: AxiosResponse<Driver>) => {
          commit('LOGIN', r.data)
          dispatch('sleep', 'update_driver')
          return r.data
        },
      )
  },
  inviteDriver({}, payload) {
    return sdk.people.inviteDriver(payload)
      .then((r: AxiosResponse) => {
        return r.data
      })
  },
  inviteClient({}, payload) {
    return sdk.people.inviteClient(payload)
      .then((r: AxiosResponse) => {
        return r.data
      })
  },
  getDocuments({commit, dispatch}) {
    return sdk.people.getDocuments()
      .then((r: AxiosResponse<Document[]>) => {
        commit('DOCUMENTS', r.data)
        dispatch('sleep', 'documents')
      })
  },
  getSubscriptions({commit, dispatch}) {
    return sdk.subscription.subscriptions()
      .then((r: AxiosResponse<Subscription[]>) => {
        commit('SUBSCRIPTIONS', r.data)
        dispatch('sleep', 'subscriptions')
        EventBus.$emit('subscriptions')
        return r.data
      })
  },
  subscribe({commit}, payload: string) {
    return sdk.subscription.subscribe(payload)
      .then((r: AxiosResponse<Subscription>) => {
        commit('SUBSCRIPTION', r.data)
        EventBus.$emit('subscriptions')
        return r.data
      })
  },
  autorenew({commit, dispatch}, payload: Autorenew) {
    return sdk.subscription.autoRenew(payload).then((r) => {
      commit('AUTORENEW', r.data)
      return r.data
    })
  },
  getReports({commit, dispatch}) {
    return sdk.reporting.list()
      .then((r: AxiosResponse<[Report]>) => {
        commit('REPORTS', r.data)
        dispatch('sleep', 'reports')
        EventBus.$emit('reports')
        return r.data
      })
  },
  logout({dispatch}) {
    FirebaseHelper.unregister()
    sdk.profile.logout().finally(() => {
      dispatch('updateUserToken', null).then(() => {
        dispatch('purge')
        dispatch('purgeAccounts')
        dispatch('closeOverlays')
        EventBus.$emit('logout')
      })
    })
  },
  resetPassword({}, payload: ResetPasswordRequest) {
    return sdk.people.forgotPassword(payload)
  },
  requestPhoneVerification({}, payload: LoginByPhoneCodeRequest) {
    return sdk.people.requestPhoneVerification(payload).then((r) => r.data)
  },
  phoneVerification({}, payload: LoginByPhoneRequest) {
    return sdk.people.phoneVerification(payload).then((r) => r.data)
  },
  requestLoginByPhone({}, payload: LoginByPhoneCodeRequest) {
    return sdk.people.requestLoginByPhone(payload).then((r) => r.data)
  },
  loginByPhone({commit, dispatch}, payload: LoginByPhoneRequest) {
    return sdk.people.loginByPhone(payload).then((r: AxiosResponse<LoginResponse>) => {
      dispatch('updateUserToken', r.data.token)
      commit('LOGIN', r.data.driver)
      dispatch('loginDispatch', true)
      EventBus.$emit('loginByPhone')
      if (r.data.pin) {
        EventBus.$emit('pinReminder', r.data.pin)
      }
      return r.data
    })
  },
  login({commit, dispatch}, payload: LoginRequest) {
    return sdk.people.login(payload).then((r: AxiosResponse<LoginResponse>) => {
      dispatch('updateUserToken', r.data.token)
      commit('LOGIN', r.data.driver)
      dispatch('loginDispatch', true)
      if (r.data.pin) {
        EventBus.$emit('pinReminder', r.data.pin)
      }
      return r.data
    }).catch(
      // (e) => {console.log(e)}
    )
  },
  loginFromRegistrationResponse({commit, dispatch}, payload: QuickRegistrationResponse) {
    dispatch('updateUserToken', payload.token)
    commit('LOGIN', payload.driver)
    dispatch('loginDispatch', false)
    // if (payload.pin) {
    //   EventBus.$emit('pinReminder', payload.pin)
    // }
  },
  loginFromTokenQuick({commit, dispatch}, token: string): Promise<void> {
    return dispatch('updateUserToken', token)
  },
  addProfile({commit, dispatch, state, rootState}, token: string) {
    const currentDriverId = state.driver?.id
    const currentToken = rootState.userToken
    return new Promise<void>((resolve) => {
      if (currentDriverId) {
        dispatch('loginFromTokenQuick', token).then(() => {
          dispatch('saveAccount').then(() => {
            dispatch('loginFromTokenQuick', currentToken).then(() => {
              dispatch('removeAccount', currentDriverId)
              resolve()
            })
          })
        })
      }
    })
  },
  loginByPhoneQuick({commit, dispatch}, payload: LoginByPhoneRequest): Promise<LoginResponse> {
    return new Promise((resolve, reject) => {
      sdk.people.loginByPhone(payload).then((r: AxiosResponse<LoginResponse>) => {
        resolve(r.data)
      }).catch(reject)
    })
  },
  switchProfile({commit, dispatch}, token: string) {
    return new Promise((resolve, reject) => {
      dispatch('purge').then(() => {
        dispatch('updateUserToken', token).then(() => {
          dispatch('getDriver').then(() => {
            dispatch('loginDispatch', false).then(resolve)
          }).catch(reject)
        })
      }).catch(reject)
    })
  },
  registration({}, payload: RegistrationRequest) {
    return sdk.people.register(payload).then((r: AxiosResponse) => {
      return r.data
    })
  },
  quickRegistration({}, payload: QuickRegistrationRequest) {
    return sdk.people.quickRegister(payload).then((r: AxiosResponse<Client>) => {
      return r.data
    }).catch((e: AxiosError) => {
      if (e.response && e.response.data.result_code === 'exceptions.system.already-has-account-exception') {
        throw e
      }
    })
  },
  loginDispatch({dispatch}) {
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        dispatch('closeOverlays'),
        dispatch('getWallets'),
        dispatch('serviceMeshes'),
        dispatch('getUserMesh'),
        dispatch('categories'),
        dispatch('getFlows'),
        dispatch('getPersistent'),
        dispatch('timestamps'),
      ]).then(() => {
        dispatch('sleep', 'login_dispatcher')
      }).finally(() => {
        EventBus.$emit('login')
        resolve()
      }).catch(reject)
    })
  },
  addDocument({dispatch}, payload: DriverLicense) {
    return sdk.people.addDocument(payload)
      .then((r: AxiosResponse<Document>) => {
        dispatch('getDocuments')
        return r.data
      })
  },
  buyTopUpCredit({dispatch}, payload: number) {
    return sdk.billing.topUpCredit(payload).then((r) => {
      dispatch('getWallets')
      return r.data
    })
  },
  suspendDriver({dispatch}, payload: Driver) {
    return new Promise<void>((resolve) => {
      sdk.people.suspendDriver(payload).then(() => {
        dispatch('getDrivers').then(() => {
          resolve()
        })
      })
    })
  },
  reactivateDriver({dispatch}, payload: Driver) {
    return new Promise<void>((resolve) => {
      sdk.people.reactivateDriver(payload).then(() => {
        dispatch('getDrivers').then(() => {
          resolve()
        })
      })
    })
  },
  setLang({commit, dispatch}, payload: string) {
    commit('SET_LANG', payload)
    dispatch('sleep', 'set_lang')
  },
  addProfilePicture({commit}, payload: ProfileImageRequest) {
    return sdk.people.addProfilePicture(payload).then((r) => {
      const pic = r.data.profile_picture
      commit('SET_DRIVER_PROFILE_PICTURE', pic)
      return pic
    })
  },
  updateProfilePicture({state, commit}, picture_url?: string) {
    const pic = state.driver ? `${picture_url || state.driver?.profile_picture}` : null
    if (pic) {
      commit('SET_DRIVER_PROFILE_PICTURE', pic)
      EventBus.$emit('refreshButtons')
      return pic
    }
  },
  accountStatus({commit}) {
    return new Promise((resolve) => {
      sdk.people.accountStatus().then((r) => {
        commit('SET_ACCOUNT_STATUS', r.data)
        if (typeof r.data.driver_license !== 'undefined') {
          Vue.set(r.data, 'document', r.data.driver_license)
          delete r.data.driver_license
        }
        resolve(r.data)
      })
    })
  },
  setUserPosition({commit}, payload: Position) {
    commit('SET_USER_POSITION', payload)
  },
  setDebugMode({commit}, payload: boolean) {
    commit('SET_DEBUG_MODE', payload)
  },
  setTranslationMode({commit}, payload: boolean) {
    commit('SET_TRANSLATION_MODE', payload)
  },
  setDebugTab({commit}, payload: number) {
    commit('SET_DEBUG_TAB', payload)
  },
  setSplashShow({commit, dispatch}, payload: boolean) {
    commit('SET_SPLASH_SHOW', payload)
    dispatch('sleep', payload ? 'show_splash' : 'hide_splash')
  },
}

const getters: GetterTree<ProfileState, RootState> = {
  debugTab: (s) => s.debugTab,
  debugMode: (s) => s.debugMode,
  translationMode: (s) => s.translationMode,
  mainDriver: (s) => !!s.driver?.main_driver,
  hasDrivers: (s) => !!s.drivers.filter((d) => !d.main_driver).length,
  maintenance: (s) => s.client?.client_type.type === 'MAINTENANCE',
  userPosition: (s, g, rs, rg) => {
    const {lat, lng, acc} = s.userPosition || rg.defaultLocation || {lat: 0, lng: 0, acc: null}
    return {lat, lng, acc: acc ?? null}
  },
  showSplash: (s) => s.showSplash,
}

export default {
  state: new ProfileState(),
  mutations,
  actions,
  getters,
}
