import {ActionTree, GetterTree, MutationTree} from 'vuex'
import {RootState} from '@/store'
import sdk from '@/lib/kepler/sdk'
import {EnrichedPoiData, MapMarker, ParkingLot, Poi, Position, SearchRequest, ServiceZone, VehicleSlot, Zone} from '@/lib/kepler/interfaces'
import ServiceMesh from '@/lib/serviceMesh'
import Utils from '@/utils'
import {FilterButton} from '@/store/modules/filters'
//
// type ValueOf<T> = T[keyof T]
//
// export interface Filter<T extends ParkingLot | EnrichedPoiData | MapMarker | Zone> {
//   [id: string]: {
//     active: boolean,
//     data: T[],
//     icon?: string,
//     color?: string,
//   }
// }

export interface SearchParameters {
  [name: string]: string | number,
}

interface MapSearchReq extends SearchRequest {
  poi: string[]
  park: string[]
  zones: string[]
}

export class SearchReq {
  public latitude
  public longitude
  public start
  public end
  public min_range

  constructor(
    latitude?: number,
    longitude?: number,
    start?: string,
    end?: string,
    min_range?: number,
  ) {
    this.latitude = latitude || null
    this.longitude = longitude || null
    this.start = start || undefined
    this.end = end || undefined
    this.min_range = min_range || 0
  }
}

export type SearchR = Partial<typeof SearchReq>

export class ItemsResponse {
  public poi: Poi[] = []
  public vehicles: VehicleSlot[] = []
  public zones: ServiceZone[] = []
  public parking: ParkingLot[] = []
  public evCharging: any[] = []
}

export class MapItemsState {
  public itemsRequest = new SearchReq()
  public itemsResponse = new ItemsResponse()
}

const mutations: MutationTree<MapItemsState> = {
  MODIFY_REQUEST: (state, payload: Partial<MapSearchReq>) => {
    state.itemsRequest = {...state.itemsRequest, ...payload}
  },
  MODIFY_ITEMS_RESPONSE: (state, payload: { item: keyof ItemsResponse, value: any }) => {
    state.itemsResponse[payload.item] = payload.value
  },
}

const actions: ActionTree<MapItemsState, RootState> = {
  setMapItems({commit}, payload: Partial<MapSearchReq>) {
    commit('MODIFY_REQUEST', payload)
  },
  _initFilters({dispatch}) {
    return new Promise<void>((resolve, reject) => {
      Promise.allSettled([
        dispatch('_getPoi'),
        dispatch('_getServiceZones'),
        dispatch('_getParkingLots'),
        dispatch('_getVehicleSlots'),
      ]).then(() => {
        resolve()
      }).catch(reject)
    })
  },
  modifyMapItemsRequest({commit, dispatch}, req: SearchR) {
    commit('MODIFY_REQUEST', req)
    const reqHas = (keys: Array<keyof SearchReq>) => keys.some((key) => key in req)

    if (reqHas(['min_range', 'start', 'end', 'latitude', 'longitude'])) {
      dispatch('_getVehicleSlots')
    }
  },
  resetMapItemsRequest({commit, dispatch}) {
    commit('MODIFY_REQUEST', new SearchReq())
    dispatch('_getVehicleSlots')
  },
  _getPoi({commit}) {
    return sdk.map.getPoi()
      .then(({data}) => {
        commit('MODIFY_ITEMS_RESPONSE', {
          item: 'poi', value: data.map((p) => {
            return {...p, id: 'poi-' + p.id}
          }),
        })
      })
  },
  _getServiceZones({commit}) {
    return sdk.map.serviceZones()
      .then(({data}) => {
        commit('MODIFY_ITEMS_RESPONSE', {
          item: 'zones', value: data.map((z) => {
            return {...z, id: 'zone-' + z.id}
          }),
        })
      })
  },
  _getParkingLots({commit, rootGetters}) {
    return sdk.booking.getParkingLots(rootGetters.userPosition)
      .then(({data}) => {
        commit('MODIFY_ITEMS_RESPONSE', {item: 'parking', value: data})
      })
  },
  _getVehicleSlots({state, commit, rootGetters}) {
    const position = rootGetters.userPosition
    sdk.booking.serviceMesh().then(() => {
      const req = state.itemsRequest

      function orNot<T>(v: T) {
        if (!!v) {
          return v
        }
      }

      const searchReq = {
        latitude: position.lat,
        longitude: position.lng,
        min_range: req.min_range || undefined,
        // vehicle_category_id: orNot(req.vehicle_category_id),
        // vehicle_type_id: orNot(req.vehicle_type_id),
        // booking_mode: orNot(req.booking_mode),
        start: orNot(req.start),
        end: orNot(req.end),
      }
      sdk.booking.search(searchReq).then(({data}) => {
        commit('MODIFY_ITEMS_RESPONSE', {item: 'vehicles', value: data})
      })
    })
  },
  getEVChargingStations({state, commit, rootGetters}, radius: number) {
    const p = rootGetters.userPosition
    const params = {lat: p.lat, lng: p.lng, radius}
    return sdk.map.getEvChargingStations(params)
      .then(({data}) => {
        commit('MODIFY_ITEMS_RESPONSE', {item: 'evCharging', value: data})
      })
  },
}

const getters: GetterTree<MapItemsState, RootState> = {
  filteredMapVehicles: ({itemsResponse}, g, r, {getEnabledFilterButtons}) => {
    const vehicleSlots = itemsResponse?.vehicles || []
    const obj: { [name: string]: VehicleSlot[] } = {}
    if (Array.isArray(vehicleSlots)) {
      // filter
      const filteredVehicleSlots = vehicleSlots.filter((vs) => {
        return getEnabledFilterButtons?.some((button: Omit<FilterButton, 'icon' | 'name' | 'small' | 'color' | 'enabled'>) => {
          const bm = button.booking_mode?.includes(vs.reservation_type)
          const vt = button.vehicle_types?.includes(vs.vehicle.category.type)
          // TODO: figure out categories too
          return bm && vt
        })
      })

      // group by bmvt
      filteredVehicleSlots.forEach((p) => {
        const id = p.reservation_type + p.vehicle.category.type.toUpperCase()
        if (!obj[id]) {
          obj[id] = []
        }
        obj[id].push(p)
      })
    }

    const mapMarkers: Array<MapMarker<VehicleSlot>> = []

    Object.entries(obj).forEach(([id, vehicles]) => {
      const sm = new ServiceMesh()
      const v = vehicles as VehicleSlot[]

      function hashByPos(p: Position) {
        const lat = String(p.latitude)
        const lng = String(p.longitude)
        return String(Utils.hash(lat + lng))
      }

      const slotsByPos: Record<string, VehicleSlot[]> = {}
      v.forEach((vs) => {
        const hash = hashByPos(vs.position)
        if (hash in slotsByPos) {
          slotsByPos[hash] = [...slotsByPos[hash], vs]
        } else {
          slotsByPos[hash] = [vs]
        }
      })

      for (const key in slotsByPos) {
        if (key in slotsByPos) {
          const items = slotsByPos[key]
          const first = items[0]
          mapMarkers.push({
            items,
            key,
            icon: sm.getImageForVehicle(first, 'OK'),
            distance: first.distance,
            position: {
              lat: first.position.latitude,
              lng: first.position.longitude,
            },
          })
        }
      }
    })

    return mapMarkers
  },
  filteredMapPoi: ({itemsResponse}, g, r, {getEnabledFilterButtons}) => {
    let poiMarkers: EnrichedPoiData[] = []

    // filter
    const filteredItems = itemsResponse.poi?.filter((poi) => {
      return getEnabledFilterButtons?.some((button: Omit<FilterButton, 'icon' | 'name' | 'small' | 'color' | 'enabled'>) => {
        return button.poi?.includes(poi.id)
      })
    })

    // get markered
    filteredItems.forEach((p) => {
      poiMarkers = [...p.data.map((poiData) => {
        return {
          ...poiData,
          id: p.id,
          icon: p.icon,
          visibility: p.visibility,
          name: p.name,
          display_text: p.display_text,
        }
      }), ...poiMarkers]
    })
    return poiMarkers
  },
  filteredMapZones: ({itemsResponse}, g, r, {getEnabledFilterButtons}): Zone[] => {
    const zones: Zone[] = []
    // filter
    const filteredItems = itemsResponse.zones?.filter((zone) => {
      return getEnabledFilterButtons?.some((button: Omit<FilterButton, 'icon' | 'name' | 'small' | 'color' | 'enabled'>) => {
        return button.zones?.includes(zone.id)
      })
    })
    filteredItems.forEach((s) => {
      zones.push(...s.zones)
    })
    return zones
  },
  filteredMapParkings: ({itemsResponse}, g, r, {getEnabledFilterButtons}) => {
    return itemsResponse.parking?.filter((park) => {
      return getEnabledFilterButtons?.some((button: Omit<FilterButton, 'icon' | 'name' | 'small' | 'color' | 'enabled'>) => {
        return button.parkings?.includes(park.id)
      })
    })
  },
  filteredEVChargingStations: ({itemsResponse}) => {
    return itemsResponse.evCharging
  },
}

export default {
  state: new MapItemsState(),
  mutations,
  actions,
  getters,
}
