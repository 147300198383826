import {ActionTree, GetterTree, MutationTree} from 'vuex'
import sdk from '@/lib/kepler/sdk'
import {AppConfig, CheckpointRequest, HomeModeEnum, SplashButtons} from '@/lib/kepler/interfaces'
import {EventBus} from '@/main'
import {RootState} from '@/store'
import Vue from 'vue'

interface AppVersion {
  version: number
  version_required: number
}

interface AppRelease {
  release: string
  release_required: string
}

export class AppConfigState {
  public appConfig!: AppConfig
  public versionNumber: number = 0
  public versionNumberRequired: number = 0
  public release: string = ''
  public releaseRequired: string = ''
  public homeMode: HomeModeEnum = HomeModeEnum.map
}

const mutations: MutationTree<AppConfigState> = {
  SET_CONFIG(cfState, payload: AppConfig) {
    cfState.appConfig = payload
  },
  SET_APP_VERSION(cfState, payload: AppVersion) {
    cfState.versionNumber = payload.version
    cfState.versionNumberRequired = payload.version_required
  },
  SET_APP_RELEASE(cfState, payload: AppRelease) {
    cfState.release = payload.release
    cfState.releaseRequired = payload.release_required
  },
  SET_HOME_MODE(cfState, payload?: HomeModeEnum) {
    if (payload) {
      cfState.homeMode = payload
    } else if (!cfState.homeMode) {
      cfState.homeMode = HomeModeEnum.map
    }
  },
  SET_SPLASH(cfState, payload: SplashButtons[]) {
    cfState.appConfig.splash_configuration = payload
  },
}

const actions: ActionTree<AppConfigState, RootState> = {
  init({dispatch}) {
    dispatch('loadConfig')
    dispatch('setHomeMode')
  },
  loadConfig({commit, dispatch}) {
    return sdk.app.config().then((response) => {
      commit('SET_CONFIG', response.data)
      dispatch('sleep', 'config')
      EventBus.$emit('configLoaded')
      return response.data
    })
  },
  checkpoint({commit, dispatch}, payload: CheckpointRequest) {
    return sdk.profile.checkpoint(payload).then((r) => {
      commit('SET_APP_VERSION', {
        version: Number(Vue.prototype.$env.APP_VERSION_NUMBER),
        version_required: r.data.current_level || 0,
      })
      commit('SET_APP_RELEASE', {
        release: Vue.prototype.$env.RELEASE,
        release_required: r.data.current_release,
      })
      return r.data
    })
  },
  setHomeMode({commit}, homeMode?: keyof typeof HomeModeEnum) {
    if (homeMode) {
      commit('SET_HOME_MODE', homeMode)
    }
  },
  setSplash({commit}, splashConfig: SplashButtons[]) {
    commit('SET_SPLASH', splashConfig)
  },
}

const getters: GetterTree<AppConfigState, RootState> = {
  defaultLocation: (state) => {
    const lat = state.appConfig.default_latitude
    const lng = state.appConfig.default_longitude
    if (lat && lng) {
      return {lat, lng, acc: null}
    }
    return null
  },
  defaultPlanId: (state) => state.appConfig?.default_plan,
  remindBookingModesThreshold: (state) => state.appConfig?.bm_reminder_threshold,
  fieldConfigs: (state) => state.appConfig?.fields_configuration,
  appConfig: (state) => state.appConfig,
  splashButtons: (state) => state.appConfig.splash_configuration,
}

export default {
  state: new AppConfigState(),
  mutations,
  actions,
  getters,
}
